<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col
          cols="12"
        >
          <b-card-body>
            <div style="display: flex; justify-content: space-between;">
              <div class="titulo-pagina">
                Obras
              </div>
              <b-button
                  variant="outline-success"
                  to="/admin/obra/adicionar"
              >
                Cadastrar Obra
              </b-button>
            </div>
            <b-row class="mb-1">
              <b-col
                md="9"
              />
              <b-col md="3" />
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <!--                      <b-button-->
                      <!--                          variant="gradient-warning"-->
                      <!--                          pill-->
                      <!--                          class="mr-1"-->
                      <!--                          @click="maisopcoes(item)"-->
                      <!--                      >-->
                      <!--                        + Opções-->
                      <!--                      </b-button>-->
                      <b-button
                        variant="info"
                        class="btn-icon rounded-circle"
                        @click="EditarProjeto(item)"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon rounded-circle mx-1"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                      <b-button
                        variant="warning"
                        class="btn-icon rounded-circle"
                        @click="Permissao(item)"
                      >
                        <feather-icon icon="UnlockIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'status'">
                      <b-badge
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'IndexContratoLista',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      // ------------- DADOS TABELA -------- \\
      url: 'admin/obra',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'nome',
          orderable: true,
        },
        {
          label: 'cliente',
          name: 'cliente.nome',
          orderable: true,
        },
        {
          label: 'contrato',
          name: 'numero_contrato',
          orderable: true,
        },
        {
          label: 'status',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      // -------------------------- Dados Banco -------------------------- \\
      contrato: '',
      opcoescontrato: [],
      id: null,
      obra: '',
      nome: '',
      descricao: '',
      cliente: '',
      opcoescliente: [],
      status: '',
      encarregado: '',
      opcoesencarregado: [],
      opcoesstatus: [
        { id: 1, title: 'ativo' },
        { id: 2, title: 'Inativo' },
      ],
    }
  },
  watch: {
    cliente(N, Y) {
      if (Y) this.contrato = null
    },
  },
  async created() {
    this.$loading(true)
    this.BuscarDados(this.url)
    await this.$http.get('admin/clientes_ver').then(cliente => {
      this.opcoescliente = cliente.data
    })
  },
  methods: {
    SalvarPermissao() {
      const user = {
        obra_id: this.obra.id,
        user_id: this.encarregado.id,
      }
      this.$loading(true)
      this.$bvModal.hide('popupPermissao')
      this.$http.post('admin/obra_user', user)
      this.$loading(false)
    },
    async Permissao(item) {
      localStorage.setItem('admin/permissao', JSON.stringify(item))
      await this.$router.push({
        name: 'adm-obra-permissoes',
        params: {
          id: item.id,
        },
      })
    },
    async BuscarEncarregado() {
      await this.$http.get('admin/buscar_usuarios').then(contrato => {
        this.opcoesencarregado = contrato.data.data
      })
    },
    async buscarContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.contrato = null
      this.nome = null
      this.descricao = null
      this.cliente = null
      this.status = null
    },
    SalvarCliente() {
      const obj = {
        contrato_id: this.contrato.value,
        nome: this.nome,
        descricao: this.descricao,
        cliente_id: this.cliente.value,
        status: this.status.id,
      }
      // --------------------- FUNÇÃO DE EDITAR ---------------------------- //
      this.$http.put(`admin/obra/${this.id}`, obj).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.$bvModal.hide('popupEditar')
        this.reloadTable()
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'secondary', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'success', msg: 'Ativo' }
      }
      return { cor: 'danger', msg: 'Sem status' }
    },
    EditarProjeto(item) {
      localStorage.setItem('admin/edicao', JSON.stringify(item))
      this.$router.push({
        name: 'adm-obra-ver',
        params: {
          id: item.id,
        },
      })
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/obra/${item.id}`)
              this.Notificacao('success', 'Obra Removida com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
